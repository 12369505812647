.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.cstmLayout {
  flex-direction: row;
  background: #0c1228;
  display: block;
  height: 100%;
  position: relative;
  z-index: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropdown .ant-select-item {
  padding: 0;
  background-color: #0c1228;
}

.token-item-selector {
  display: flex;
  padding: 20px;
  flex-direction: row;
  align-items: center;
}

.token-item-selector .in-row {
  width: 100%;
  display: flex;
  align-items: center;
}

.token-item-selector .token-info {
  margin-left: 20px;
}

.token-item-selector .token-info .token-title {
  font-weight: 600;
  font-size: 18px;
}
.token-item-selector .token-info .token-subtitle {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.9;
  text-align: left;
  color: #767676;
  word-break: break-all;
}

.token-item-selector .token-info .erc-block {
  border-radius: 2.5px;
  background-color: #f4f3f5;
  margin-left: 15px;
  padding: 4px;
}

.erc-txt {
  font-size: 10px;
  font-weight: 700;
  text-align: left;
  color: #282828;
}
.token-logo {
  width: 25px;
  height: 25px;
  object-fit: contain;
  align-self: flex-start;
  margin-top: 10px;
}

#splash {
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cookie-block {
  width: 100%;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  padding: 20px 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  box-shadow: 0px -1px 0 0 rgba(0, 0, 0, 0.05);
}

.cookie-title {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.39;
  text-align: left;
  color: #ffffff;
}

.cookie-content {
  font-size: 15px;
  font-weight: 500;
  line-height: 2.08;
  text-align: left;
  color: #999999;
}

.cookie-btn {
  background-color: #174AF4;
  border-radius: 5px;
  font-weight: 600;
  padding: 10px 20px;
  font-size: 15px;
  line-height: 1.77;
  letter-spacing: 0.6px;
  text-align: center;
  color: #ffffff;
  margin-left: 30px;
  margin-top: 10px;
}

.txt-privacy {
  font-weight: 600;
  border-bottom: 1px solid;
  padding-bottom: 2px;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 1220px) {
  .cookie-block {
    padding: 20px 15px;
  }
}

@media only screen and (max-width: 1086px) {
  .cookie-btn {
    padding: 10px;
    margin-left: 15px;
  }
}

@media only screen and (max-width: 992px) {
  .cookie-btn {
    padding: 10px 20px;
  }
  .btn-container {
    justify-content: flex-start;
  }
}

.antd-modal-content-border-15 .ant-modal-content {
  border-radius: 15px;
}

.ant-modal-content {
  background-color: #0c1228;
  border: 1px solid #04E9BC;
  border-radius: 20px;
}

.search-token-dropdown {
  border: solid 1px #04E9BC;
  background-color: #0c1228;
}

.search-token-dropdown, .search-token-dropdown .ant-select-item {
  padding: 0;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #0c1228;
}

.search-result {
  padding: 10px;
}

.search-token-dropdown .lbl {
  padding: 10px;
  font-family: "Helvetica Neue", Roboto;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.15px;
  text-align: left;
  color: #32737e;
  display: flex;
}

.search-result .item {
  text-align: left;
}

.search-result .item img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-right: 5px;
}

.search-result span {
  font-family: "Helvetica Neue", Roboto;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: #87879f;
}

.search-result .btn-row {
  display: flex;
  justify-content: flex-end;
}

.search-result .btn-row .select-btn {
  background-color: transparent;
  border: 1px solid #04E9BC;
  border-radius: 5px;
}

.search-result .btn-row .select-btn p {
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  color: #04E9BC;
}
