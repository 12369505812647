body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Roboto',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  border-style: none;
}
button:hover {
  cursor: pointer;
}

p {
  margin-bottom: 0;
}

p, h1, h2, h3, h4, h5, h6 {
  font-family: 'Helvetica Neue', 'Roboto';
}

@media
  (-moz-windows-theme) {
  p, h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto';
  }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes moveUp {
  0% { top: 0; }
  100% { top: -100px; }
}

@keyframes moveDown {
  0% { top: -100px; }
  100% { top: 0; }
}
